<template>
<div>
  <van-cell-group title="查询">
<!--    <van-cell title="扫码查询" is-link size="large" icon="scan" to="/cap/scan" />-->
    <van-cell title="所有记录" is-link size="large" icon="search" to="/cap/lst" />
  </van-cell-group>
</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped></style>